import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Radio, Steps, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Barcode from 'react-barcode';
import StepWizard from 'react-step-wizard';
import jwtDecode from 'jwt-decode';
import { useIntl } from 'react-intl';
import './RegisterShipment.less';

const { Step } = Steps;

function RegisterShipment() {
    const [form, setForm] = useState({});
    const [activeStep, setActiveStep] = useState(1);
    const [accessToken, setAccessToken] = useState('');
    const [selectedBox, setSelectedBox] = useState(null);
    const [creatingDelivery, setCreatingDelivery] = useState(false);
    const [authenticating, setAuthenticating] = useState(false);
    const [availableBoxSizes, setAvailableBoxSizes] = useState([]);
    const [deliveryCode, setDeliveryCode] = useState(null);
    const [companyId] = useState(1);

    //mainInput is always the input that should autmatically get focus
    let mainInput = React.createRef();
    useEffect(() => {
        mainInput.current?.focus();
    });

    const branchId = 40055;
    const boxDepth = '58';
    const intl = useIntl();

    const updateForm = (values) => {
        setForm({ ...form, ...values });
    };

    const goToFirstStep = (props) => {
        props.firstStep();
        return null;
    };

    const barcodeOptions = {
        height: 150,
        format: 'CODE128',
        lineColor: '#0057A4',
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 20,
        marginRight: 20,
    };

    const validatePhoneNumberLength = () => ({
        validateTrigger: ['onSubmit', 'onBlur'],
        validator(rule, value) {
            if (value && value.length < 7) {
                return Promise.reject(intl.formatMessage({ id: 'phone.validation.length.error' }));
            }
            return Promise.resolve();
        },
    });

    const validatePhoneNumber = () => ({
        validateTrigger: ['onSubmit', 'onBlur'],
        validator(rule, value) {
            if (value && isNaN(value)) {
                return Promise.reject(intl.formatMessage({ id: 'phone.validation.number.error' }));
            }
            return Promise.resolve();
        },
    });

    const renderBox = (boxSize) => {
        if (!boxSize) return null;
        let dimensions = getBoxDimensions(boxSize);
        return <div className="box" style={{ width: dimensions[0], height: dimensions[1] }} />;
    };

    const getBoxDimensions = (boxSize) => {
        let dimensions = boxSize.split('x');
        let width = parseInt(dimensions[0]) * 3;
        let height = parseInt(dimensions[1]) * 3;
        return [width, height];
    };

    const getBoxObject = (boxSize) => {
        for (const oneSize of availableBoxSizes) {
            if (oneSize.size === boxSize) return oneSize;
        }
    };

    const getBoxSizeText = (boxSize) => {
        return boxSize + 'x' + boxDepth + ' cm';
    };

    const isTokenExpired = (token) => {
        let decodedToken = jwtDecode(token);
        let expiry = decodedToken.exp;
        let now = new Date().getTime();
        return !expiry || expiry * 1000 < now;
    };

    const handleExpiredToken = (props) => {
        message.error(intl.formatMessage({ id: 'authentication.expired' }));
        props.firstStep();
    };

    const fetchAvailableBoxes = () => {
        return fetch(`/api/site/${branchId}/lockers`, {
            method: 'GET',
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(intl.formatMessage({ id: 'get.boxes.error' }));
                }
                return response.json();
            })
            .then((json) => {
                setAvailableBoxSizes(json);
            })
            .catch((error) => console.log(error));
    };

    const checkAccessTokenAndUpdateForm = (values, props) => {
        if (isTokenExpired(accessToken)) {
            handleExpiredToken(props);
        } else {
            updateForm(values);
            props.nextStep();
        }
    };

    const shortFormItemLayout = {
        wrapperCol: {
            xs: { span: 8 },
            sm: { span: 8 },
        },
    };

    const longFormItemLayout = {
        wrapperCol: {
            xs: { span: 16 },
        },
        labelCol: {
            xs: { span: 8 },
        },
        labelAlign: 'left',
    };

    const Step1 = (props) => {
        if (!window.location.hash) window.location.hash = 'simanumer';
        return (
            <Form
                {...shortFormItemLayout}
                className="step"
                onFinish={(values) => {
                    updateForm(values);
                    fetch(`/api/auth/${values.phoneNumber}`, {
                        method: 'POST',
                    });
                    props.nextStep();
                }}
            >
                <p>{intl.formatMessage({ id: 'step1.welcome' })}</p>
                <p>{intl.formatMessage({ id: 'step1.instructions' })}</p>
                <Form.Item
                    name="phoneNumber"
                    label={intl.formatMessage({ id: 'step1.phone.label' })}
                    hasFeedback
                    required={false}
                    defaultValue={form.phoneNumber}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: 'step1.phone.required' }),
                        },
                        validatePhoneNumberLength,
                        validatePhoneNumber,
                    ]}
                >
                    <Input ref={mainInput} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'submit'}>{intl.formatMessage({ id: 'next.step' })}</Button>
                </Form.Item>
            </Form>
        );
    };
    const Step2 = (props) => {
        if (!form.phoneNumber) return goToFirstStep(props);
        return (
            <Form
                {...shortFormItemLayout}
                className="step"
                onFinish={(values) => {
                    updateForm(values);
                    setAuthenticating(true);
                    fetch(`/api/auth/${form.phoneNumber}/${values.accessCode}`, {
                        method: 'POST',
                    })
                        .then((response) => {
                            if (!response.ok) {
                                message.error(intl.formatMessage({ id: 'step2.code.not.valid' }));
                                throw Error(intl.formatMessage({ id: 'invalid.code.error' }));
                            }
                            return response.json();
                        })
                        .then((data) => setAccessToken(data.token))
                        .then(() => fetchAvailableBoxes())
                        .then(() => props.nextStep())
                        .then(() => setAuthenticating(false))
                        .catch((error) => {
                            console.log(error);
                            setAuthenticating(false);
                        });
                }}
            >
                <p>{intl.formatMessage({ id: 'step2.instructions' })}</p>
                <Form.Item
                    name="accessCode"
                    label={intl.formatMessage({ id: 'step2.code.label' })}
                    defaultValue={form.accessCode}
                    hasFeedback
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: 'step2.code.required' }),
                        },
                        () => ({
                            validateTrigger: ['onSubmit', 'onBlur'],
                            validator(rule, value) {
                                if (value && value.length < 4) {
                                    return Promise.reject(
                                        intl.formatMessage({
                                            id: 'step2.code.validation.error.length',
                                        })
                                    );
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input ref={mainInput} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'submit'} loading={authenticating}>
                        {intl.formatMessage({ id: 'next.step' })}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button onClick={() => props.previousStep()}>
                        {intl.formatMessage({ id: 'step2.use.another.phone' })}
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const Step3 = (props) => {
        if (!accessToken || availableBoxSizes.length < 1) return goToFirstStep(props);
        return (
            <Form
                className="step"
                onFinish={(values) => checkAccessTokenAndUpdateForm(values, props)}
            >
                <p>{intl.formatMessage({ id: 'step3.instructions' })}</p>
                <Form.Item
                    name="boxSize"
                    defaultValue={selectedBox ? selectedBox.size : undefined}
                    initialValue={selectedBox ? selectedBox.size : undefined}
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: 'step3.box.required' }),
                        },
                    ]}
                >
                    <Radio.Group
                        value={selectedBox ? selectedBox.size : undefined}
                        className="box-radio-selector"
                        size="medium"
                        buttonStyle="solid"
                        onChange={(e) => setSelectedBox(getBoxObject(e.target.value))}
                        ref={mainInput}
                    >
                        {availableBoxSizes.map((oneBoxSize, index) => {
                            return (
                                <div key={index}>
                                    <div
                                        className="box-wrapper"
                                        onClick={() => setSelectedBox(oneBoxSize)}
                                        style={{ height: 150 }}
                                        key={1}
                                    >
                                        {renderBox(oneBoxSize.sizeText)}
                                    </div>
                                    <Radio.Button
                                        disabled={oneBoxSize.numAvailable <= 0}
                                        value={oneBoxSize.size}
                                        key={2}
                                    >
                                        {getBoxSizeText(oneBoxSize.sizeText)}
                                    </Radio.Button>
                                </div>
                            );
                        })}
                    </Radio.Group>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'submit'}>{intl.formatMessage({ id: 'next.step' })}</Button>
                </Form.Item>
            </Form>
        );
    };

    const Step4 = (props) => {
        if (!form.boxSize) props.firstStep();
        return (
            <Form
                {...longFormItemLayout}
                className="step"
                onFinish={(values) => checkAccessTokenAndUpdateForm(values, props)}
            >
                <p>{intl.formatMessage({ id: 'step4.instructions' })}</p>
                <Form.Item
                    name="recipientPhoneNumber"
                    label={intl.formatMessage({ id: 'step4.recipient.phone.label' })}
                    defaultValue={form.recipientPhoneNumber}
                    hasFeedback
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: 'step4.recipient.phone.required' }),
                        },
                        validatePhoneNumberLength,
                        validatePhoneNumber,
                    ]}
                >
                    <Input ref={mainInput} />
                </Form.Item>
                <Form.Item
                    name="recipientEmail"
                    label={intl.formatMessage({ id: 'step4.recipient.email.label' })}
                    defaultValue={form.recipientEmail}
                    validateTrigger={['onSubmit', 'onBlur']}
                    hasFeedback
                    required={false}
                    rules={[
                        {
                            type: 'email',
                            message: intl.formatMessage({
                                id: 'step4.recipient.email.validation.error',
                            }),
                        },
                        {
                            required: true,
                            message: intl.formatMessage({ id: 'step4.recipient.email.required' }),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="recipientName"
                    label={intl.formatMessage({ id: 'step4.recipient.name.label' })}
                    defaultValue={form.recipientName}
                    hasFeedback
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({ id: 'step4.recipient.name.required' }),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="shipmentDescription"
                    label={intl.formatMessage({ id: 'step4.shipment.description.label' })}
                    defaultValue={form.shipmentDescription}
                    hasFeedback
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'step4.shipment.description.required',
                            }),
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'submit'}>{intl.formatMessage({ id: 'next.step' })}</Button>
                </Form.Item>
                <Form.Item>
                    <Button onClick={() => props.previousStep()}>
                        {intl.formatMessage({ id: 'previous.step' })}
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const Step5 = (props) => {
        //Review info
        if (!form.boxSize || !form.recipientPhoneNumber || !form.recipientEmail)
            return goToFirstStep(props);
        let boxSize = getBoxObject(form.boxSize).sizeText;
        return (
            <Form
                {...longFormItemLayout}
                className="step review"
                onFinish={() => {
                    if (isTokenExpired(accessToken)) {
                        handleExpiredToken(props);
                    } else {
                        setCreatingDelivery(true);
                        let delivery = {
                            recipientPhoneNumber: form.recipientPhoneNumber,
                            recipientEmail: form.recipientEmail,
                            recipientName: form.recipientName,
                            description: form.description,
                            boxSize: form.boxSize,
                            branchId: branchId,
                            companyId: companyId,
                        };
                        fetch('/api/delivery', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + accessToken,
                            },
                            body: JSON.stringify(delivery),
                        })
                            .then((response) => response.json())
                            .then((json) => setDeliveryCode(json))
                            .then(() => props.nextStep())
                            .then(() => setCreatingDelivery(false));
                    }
                }}
            >
                <p>{intl.formatMessage({ id: 'step5.review.info' })}</p>
                <Form.Item
                    className="boxes-locked"
                    label={intl.formatMessage({ id: 'step5.box.size' })}
                >
                    {renderBox(boxSize)}
                    <p>{getBoxSizeText(boxSize)}</p>
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'step4.recipient.phone.label' })}>
                    {form.recipientPhoneNumber}
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'step4.recipient.email.label' })}>
                    {form.recipientEmail}
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'step4.recipient.name.label' })}>
                    {form.recipientName}
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'step4.shipment.description.label' })}>
                    {form.shipmentDescription}
                </Form.Item>
                <Form.Item>
                    <Button htmlType={'submit'} loading={creatingDelivery} ref={mainInput}>
                        {intl.formatMessage({ id: 'step5.put.package.in.box' })}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button onClick={() => props.previousStep()}>
                        {intl.formatMessage({ id: 'previous.step' })}
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const Step6 = (props) => {
        //Scan code
        if (!deliveryCode) return goToFirstStep(props);
        return (
            <div className="step">
                <p>{intl.formatMessage({ id: 'step6.instructions' })}</p>
                {deliveryCode.code && (
                    <div className="barcode" ref={mainInput}>
                        <Barcode
                            {...barcodeOptions}
                            value={deliveryCode.code}
                            displayValue={false}
                        />
                    </div>
                )}
                <p>{intl.formatMessage({ id: 'step6.scanner.info1' })}</p>
                <p>{intl.formatMessage({ id: 'step6.scanner.info2' })}</p>
                <p id="readable-code">{deliveryCode.readableCode}#</p>
            </div>
        );
    };

    const getActiveStep = () => {
        switch (activeStep) {
            case 1:
            case 2:
                return 0;
            case 3:
            case 4:
            case 5:
                return 1;
            case 6:
                return 2;
        }
    };

    return (
        <div className="content">
            <Steps responsive size="default" current={getActiveStep()}>
                <Step
                    title={intl.formatMessage({ id: 'step.title.authentication' })}
                    icon={activeStep === 2 ? <LoadingOutlined /> : null}
                />
                <Step
                    title={intl.formatMessage({ id: 'step.title.register' })}
                    icon={creatingDelivery ? <LoadingOutlined /> : null}
                />
                <Step title={intl.formatMessage({ id: 'step.title.deliver' })} />
            </Steps>
            <StepWizard
                onStepChange={(state) => setActiveStep(state.activeStep)}
                isHashEnabled={true}
                className="step-wizard"
                isLazyMount={true}
            >
                <Step1 hashKey="simanumer" />
                <Step2 hashKey="sms-kodi" />
                <Step3 hashKey="velja-holf" />
                <Step4 hashKey="upplysingar-vidtakanda" />
                <Step5 hashKey="stadfesta-upplysingar" />
                <Step6 hashKey="skanna-koda" />
            </StepWizard>
        </div>
    );
}

export default RegisterShipment;
