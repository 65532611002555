import { useState } from 'react';
import RegisterShipment from './pages/RegisterShipment/RegisterShipment';
import { Layout, Select } from 'antd';
import { IntlProvider } from 'react-intl';
import locale_en from './translations/en.json';
import locale_is from './translations/is.json';
import './App.less';

const { Header, Content } = Layout;
const { Option } = Select;

function App() {
    //Using de for is locale to avoid having to import date formats and more for icelandic
    const [locale, setLocale] = useState('de');
    const messages = {
        de: locale_is,
        en: locale_en,
    };
    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <Layout id="app">
                <Header>
                    <a className="header-link" href="/">
                        Miðjan
                    </a>
                    <Select
                        className="language-select"
                        dropdownClassName="language-select-dropdown"
                        value={locale}
                        onChange={(value) => setLocale(value)}
                    >
                        <Option value="de">Íslenska</Option>
                        <Option value="en">English</Option>
                    </Select>
                </Header>
                <Content>
                    <RegisterShipment />
                </Content>
            </Layout>
        </IntlProvider>
    );
}
export default App;
